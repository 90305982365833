<template>
    <b-container>
        <h4>
            <router-link :to="{ name: 'event_settings', params: { id: id } }" class="mr-2">
                <b-icon-arrow-left-circle scale="1.5"></b-icon-arrow-left-circle>
            </router-link>
            Ticket Manager
        </h4>
        <b-alert :show="true" variant="secondary" class="mt-4">
            <b-button
                variant="primary"
                @click="downloadTicketWorkbook()"
            >
                <font-awesome-icon icon="download"></font-awesome-icon>
                Download
            </b-button>
        </b-alert>
        <b-card
            title="Filter"
            title-tag="h5"
        >
            <b-input placeholder="Search information..." v-model="filter"></b-input>
            <b-check-group
                class="mt-3"
                v-model="show"
            >
                <b-check
                    :value="1"
                >
                    Approved Tickets <b-badge variant="secondary">{{ count.approved }}</b-badge>
                </b-check>
                <b-check
                    :value="0"
                >
                    Unapproved Tickets <b-badge variant="secondary">{{ count.unapproved }}</b-badge>
                </b-check>
                <b-check
                    :value="2"
                >
                    Rejected Tickets <b-badge variant="secondary">{{ count.rejected }}</b-badge>
                </b-check>
            </b-check-group>
        </b-card>
        <b-table
            id="ticket-man-table"
            :key="show.join(',')"
            class="mt-2"
            :busy="isBusy"
            :fields="fields"
            :items="getTickets"
            :filter="filter"
            :no-provider-filtering="true"
            empty-text="There are no results that match your criteria."
            empty-filtered-text="There are no results that match your criteria."
            head-variant="dark"
            bordered
        >
            <template #cell(status)="row">
                <em :class="'text-' + status_colors[row.item.status]">
                    <font-awesome-icon :icon="status_icons[row.item.status]"></font-awesome-icon>
                    {{ statuses[row.item.status] }}
                </em>
            </template>
            <template #cell(action)="row">
                <div v-if="row.item.status == 0" class="float-right">
                    <b-button variant="success" class="mr-2" @click="changeTicketStatus(row.item, 1)">
                        <font-awesome-icon icon="check"></font-awesome-icon>
                    </b-button>
                    <b-button variant="danger" @click="changeTicketStatus(row.item, 2)">
                        <font-awesome-icon icon="times"></font-awesome-icon>
                    </b-button>
                </div>
                <div v-else class="float-right">
                    <b-button
                        variant="danger"
                        @click="confirmDelete(row.item)"
                    >
                        <font-awesome-icon icon="trash-alt"></font-awesome-icon>
                    </b-button>
                </div>
            </template>
        </b-table>
    </b-container>
</template>
<script>
import { downloadFile } from '@/api/downloadFile';
export default {
    props: ["id"],
    data() {
        return {
            isBusy: false,
            show  : [1],
            count : {},
            filter: "",
            fields: [
                { key: "ticket_number", label: "Ticket Number" },
                { key: "display_name", label: "Name on Ticket" },
                { key: "status" },
                { key: "ord_date", label: "Order Date" },
                { key: "action" }
            ],
            statuses:      ["Unapproved", "Approved", "Rejected"],
            status_icons:  ["times-circle", "check-circle", "times-circle"],
            status_colors: ["secondary", "success", "danger"]
        };
    },
    methods: {
        downloadTicketWorkbook() {
            downloadFile(`/api/events/admin/tickets/${this.id}/download`, `Event${this.id}TicketList.xlsx`);
        },
        getTickets() {
            return  this.$api.get(`/api/events/admin/tickets/${this.id}/fetch`, { params: { status: this.show } }).then((response) => {
                        this.count = response.data.count;
                        return response.data.tickets;
                    }).catch(() => {
                        return [];
                    });
        },
        confirmDelete(ticket) {
            this.$bvModal.msgBoxConfirm("Please confirm that you want to delete ticket #" + ticket.ticket_number + ".", {
                title           : "Please Confirm",
                size            : "sm",
                buttonSize      : "sm",
                okVariant       : "danger",
                okTitle         : "Delete",
                cancelTitle     : "Cancel",
                footerClass     : "p-2",
                hideHeaderClose : false,
                centered        : true
            })
            .then(deleteConfirmed => {
                if (deleteConfirmed) {
                    this.deleteTicket(ticket.id);
                }
            })
            .catch(err => {
                console.log("Error:", err);
            });
        },
        deleteTicket(ticket_id) {
            this.$api.post(`/api/events/admin/tickets/${this.id}/delete`, { ticket_id: ticket_id }).then(response => {
                this.$bvToast.toast(response.data.message, {
                    title           : "Notification",
                    autoHideDelay   : 5000,
                    appendToast     : true
                });
                this.$root.$emit("bv::refresh::table", "ticket-man-table");
            });
        },
        changeTicketStatus(ticket, status) {
            this.$api.post(
                `/api/events/admin/tickets/${this.id}/changeAcceptedStatus`,
                { ticket_id: ticket.id, status: status }
            ).then((response) => {
                this.$bvToast.toast(response.data.message, {
                    title           : "Notification",
                    autoHideDelay   : 5000,
                    appendToast     : true
                });
                this.$root.$emit("bv::refresh::table", "ticket-man-table");
            });
        }
    }
}
</script>